/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { convertirANumero } from '../../utils/currency';
import { storePerfiladorValuePropiety } from '../../utils';
import {
  DEFAULT_GASTOS_ESCRITURA_PERCENTAGE,
  DEFAULT_PLAZO_PERFILADOR,
  DEFAULT_TASAS,
  INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
} from '../../constants';
import { calcAmmountEnganche } from '../../utils/simulator.utils';

const initialState = {
  inputValue: '',
  inputNumber: 0,
  resultado: {},
  prestamoValue: 0,
  monthlyPay: 0,
  initialDataValues: {},
  engancheValue: 0,
  engancheNumber: 0,
  tasaNumber: DEFAULT_TASAS[convertirANumero(DEFAULT_PLAZO_PERFILADOR)],
  plazo: DEFAULT_PLAZO_PERFILADOR,
  plazoNumber: convertirANumero(DEFAULT_PLAZO_PERFILADOR),
  engancheMinimoPercentage: INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
  gastosEscrituraPercentage: DEFAULT_GASTOS_ESCRITURA_PERCENTAGE,
  percentageEnganche: 0,
  generalConditions: {
    engancheMinimoPercentage: INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
    gastosEscrituraPercentage: DEFAULT_GASTOS_ESCRITURA_PERCENTAGE,
    plazo: DEFAULT_PLAZO_PERFILADOR,
    plazoNumber: convertirANumero(DEFAULT_PLAZO_PERFILADOR),
  },
  inmobiliariasConditions: {}, // Objeto para almacenar condiciones por inmobiliariaId
  currentInmobiliariaId: null, // Para manejar la inmobiliaria seleccionada
};

export const perfiladorSlice = createSlice({
  name: 'perfilador',
  initialState,
  reducers: {
    setPerfiladorInputValue: (state, action) => {
      state.inputValue = action.payload;
      state.inputNumber = convertirANumero(action.payload);
    },
    setPerfiladorInputValueAndCalcEnganche: (state, action) => {
      const { value, includeDecimals, engancheMinimoPercentage } = action.payload;
      const valueNumber = convertirANumero(value);
      const { engancheNumber, enganche } = calcAmmountEnganche(
        valueNumber,
        includeDecimals,
        engancheMinimoPercentage,
      );
      state.inputValue = value;
      state.inputNumber = valueNumber;
      state.engancheValue = enganche;
      state.engancheNumber = engancheNumber;
    },
    setPerfiladorResultado: (state, action) => {
      state.resultado = action.payload;
    },
    setInitialPerfiladorPrecioInmueble: (state, action) => {
      const { precio } = action.payload;
      state.inputValue = precio;
      state.inputNumber = convertirANumero(precio);
      storePerfiladorValuePropiety(precio);
    },
    setPerfiladorPrestamoValue: (state, action) => {
      state.prestamoValue = action.payload;
    },
    setPerfiladorMonthlyPay: (state, action) => {
      state.monthlyPay = action.payload;
    },
    setPerfiladorPlazo: (state, action) => {
      const plazo = action.payload;
      const plazoNumber = convertirANumero(plazo);
      const tasaNumber = DEFAULT_TASAS[plazoNumber];

      state.plazo = plazo;
      state.plazoNumber = plazoNumber;
      state.tasaNumber = tasaNumber;
    },
    setPerfiladorInitialDataValues: (state, action) => {
      state.initialDataValues = action.payload;
    },
    setPerfiladorEnganche: (state, action) => {
      state.engancheValue = action.payload;
      state.engancheNumber = convertirANumero(action.payload);
    },
    setPerfiladorPercentageEnganche: (state, action) => {
      state.percentageEnganche = action.payload;
    },
    setPerfiladorInitialValues: (state, action) => {
      const {
        precio, enganche, plazo,
      } = action.payload;

      const currentConditions = state.currentInmobiliariaId
        ? { ...state.inmobiliariasConditions[state.currentInmobiliariaId] }
        : { ...state.generalConditions };

      state.inputValue = precio || state.inputValue;
      state.inputNumber = convertirANumero(precio) || state.inputNumber;
      state.engancheValue = enganche || state.engancheValue;
      state.engancheNumber = convertirANumero(enganche) || state.engancheNumber;
      state.plazo = plazo || currentConditions.plazo;
      state.plazoNumber = convertirANumero(plazo) || currentConditions.plazoNumber;
      state.tasaNumber = DEFAULT_TASAS[convertirANumero(plazo)] || state.tasaNumber;

      // No modificamos generalConditions, solo usamos las condiciones según corresponda.
    },
    setInmobiliariaConditions: (state, action) => {
      const { inmobiliariaId, creditConditions } = action.payload;
      const { engancheMinimoPercentage, gastosEscrituraPercentage, plazo } = creditConditions;
      const objNewConditions = {
        engancheMinimoPercentage: convertirANumero(engancheMinimoPercentage),
        gastosEscrituraPercentage: convertirANumero(gastosEscrituraPercentage),
        plazo,
        plazoNumber: convertirANumero(plazo),
      };

      state.inmobiliariasConditions[inmobiliariaId] = {
        ...state.generalConditions,
        ...objNewConditions,
      };
    },
    setCurrentInmobiliariaId: (state, action) => {
      state.currentInmobiliariaId = action.payload;
    },
    clearInmobiliariaConditions: (state) => {
      state.inmobiliariasConditions = {};
      state.currentInmobiliariaId = null;
    },
    setPerfiladorGeneralConditions: (state) => {
      state.plazo = state.generalConditions.plazo;
      state.plazoNumber = state.generalConditions.plazoNumber;
      state.tasaNumber = DEFAULT_TASAS[state.generalConditions.plazoNumber];
      state.engancheMinimoPercentage = state.generalConditions.engancheMinimoPercentage;
      state.gastosEscrituraPercentage = state.generalConditions.gastosEscrituraPercentage;
    },
    setConditionsByInmobiliariaId: (state, action) => {
      const { inmobiliariaId } = action.payload;
      const inmobiliariaConditions = state.inmobiliariasConditions[inmobiliariaId]
        ? { ...state.inmobiliariasConditions[inmobiliariaId] }
        : { ...state.generalConditions };

      state.plazo = inmobiliariaConditions.plazo || state.generalConditions.plazo;
      state.plazoNumber = inmobiliariaConditions.plazoNumber || state.generalConditions.plazoNumber;
      state.tasaNumber = DEFAULT_TASAS[inmobiliariaConditions.plazoNumber] || state.tasaNumber;
      state.engancheMinimoPercentage = inmobiliariaConditions.engancheMinimoPercentage
        || state.generalConditions.engancheMinimoPercentage;
      state.gastosEscrituraPercentage = inmobiliariaConditions.gastosEscrituraPercentage
        || state.generalConditions.gastosEscrituraPercentage;
    },
  },
});

// envolver el componente dentro del mismo context

export const {
  setPerfiladorInputValue,
  setPerfiladorResultado,
  setInitialPerfiladorPrecioInmueble,
  setPerfiladorInputValueAndCalcEnganche,
  setPerfiladorPrestamoValue,
  setPerfiladorMonthlyPay,
  setPerfiladorPlazo,
  setPerfiladorInitialDataValues,
  setPerfiladorEnganche,
  setPerfiladorPercentageEnganche,
  setPerfiladorInitialValues,
  setPerfiladorGeneralConditions,
  setInmobiliariaConditions,
  setCurrentInmobiliariaId,
  clearInmobiliariaConditions,
  setConditionsByInmobiliariaId,
} = perfiladorSlice.actions;

export default perfiladorSlice.reducer;
