import {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setPerfiladorInputValue,
  setPerfiladorResultado,
  setPerfiladorEnganche,
  setPerfiladorPlazo,
  setPerfiladorInitialDataValues,
  setPerfiladorInitialValues,
  setPerfiladorInputValueAndCalcEnganche,
  setConditionsByInmobiliariaId,
  setPerfiladorPercentageEnganche,
} from '../store/perfilador/perfiladorSlice';
import {
  selectPerfiladorEnganche,
  selectPerfiladorEngancheNumber,
  selectPerfiladorFormattedResultado,
  selectPerfiladorGastosEscrituraPercentage,
  selectPerfiladorInputNumber,
  selectPerfiladorInputValue,
  selectPerfiladorengancheMinimoPercentage,
  selectPerfiladorPlazo,
  selectPerfiladorPlazoNumber,
  selectPerfiladorTasaNumber,
  selectPerfiladorPercentageEnganche,
} from '../store/perfilador';
import {
  getPerfiladorClientIngresos,
  getPerfiladorClientValuePropNumber,
} from '../storage';
import {
  convertirAMoneda,
  getIngresosRecomendados,
  getViviendaEnganche,
  redondearNumero,
} from '../utils/currency';
import { getMonthlyPaid, storeIngresosData, storePerfiladorValuePropiety } from '../utils';
import { calcAmmountEnganche, calcAmmountGastosEscritura, calcAmmountPercentage } from '../utils/simulator.utils';

import {
  CALC_MODE_INGREOS_MENSUALES,
  CALC_MODE_PRECIO_INMUEBLE,
  DEFAULT_DECIMALES_REDONDEO,
  DEFAULT_PORCENTAJE_ENDEUDAMIENTO,
  DEFAULT_RPI,
} from '../constants';
import { PerfiladorContext } from '../context/perfilador';

const usePerfilador = ({
  calcMode,
  initialData = {},
  useDesembolsoMode = false,
  autoCalcEnganche = true,
  includeDecimals = true,
}) => {
  const dispatch = useDispatch();
  const { currentInmobiliariaId } = useContext(PerfiladorContext);
  const inputValue = useSelector(selectPerfiladorInputValue);
  const inputNumber = useSelector(selectPerfiladorInputNumber);
  const plazo = useSelector(selectPerfiladorPlazo);
  const plazoNumber = useSelector(selectPerfiladorPlazoNumber);
  const enganche = useSelector(selectPerfiladorEnganche);
  const engancheNumber = useSelector(selectPerfiladorEngancheNumber);
  const percentageEnganche = useSelector(selectPerfiladorPercentageEnganche);
  const resultado = useSelector(selectPerfiladorFormattedResultado);
  const tasaNumber = useSelector(selectPerfiladorTasaNumber);
  const engancheMinimoPercentage = useSelector(selectPerfiladorengancheMinimoPercentage);
  const gastosEscrituraPercentage = useSelector(selectPerfiladorGastosEscrituraPercentage);

  const calculoMensualidad = useCallback((importe) => {
    const tasaAnual = tasaNumber * 0.01;
    const plazoMensual = plazoNumber * 12;
    const result = getMonthlyPaid({ importe, plazoMensual, tasa: tasaAnual });
    return Number(result.toFixed(2));
  }, [plazoNumber, tasaNumber]);

  const calculoMontoCredito = useCallback((valor, engancheValue) => {
    const importe = valor - engancheValue;
    return Number(importe.toFixed(2));
  }, []);

  const calculoIngresosRecomendados = useCallback((pagoMensual) => {
    const result = getIngresosRecomendados({
      pagoMensual,
      porcentajeEndeudamiento: DEFAULT_PORCENTAJE_ENDEUDAMIENTO,
    });
    return Number(result.toFixed(2));
  }, []);

  const calcularPrecioInmueble = useCallback(() => {
    const montoCredito = calculoMontoCredito(inputNumber, engancheNumber);
    const pagoMensual = calculoMensualidad(montoCredito);
    const ingresosRecomendados = calculoIngresosRecomendados(pagoMensual);
    const montoGastosEscritura = useDesembolsoMode
      ? calcAmmountGastosEscritura(inputNumber, gastosEscrituraPercentage)
      : 0;

    return {
      propietyValue: redondearNumero(inputNumber, DEFAULT_DECIMALES_REDONDEO),
      montoCredito: redondearNumero(montoCredito, DEFAULT_DECIMALES_REDONDEO),
      pagoMensual: redondearNumero(pagoMensual, DEFAULT_DECIMALES_REDONDEO),
      ingresosRecomendados: redondearNumero(ingresosRecomendados, DEFAULT_DECIMALES_REDONDEO),
      montoGastosEscritura: redondearNumero(montoGastosEscritura, DEFAULT_DECIMALES_REDONDEO),
    };
  }, [
    inputNumber,
    engancheNumber,
    gastosEscrituraPercentage,
    tasaNumber,
    plazoNumber,
    calculoMontoCredito,
    calculoMensualidad,
    calculoIngresosRecomendados,
    useDesembolsoMode,
  ]);

  const calcularIngresosMensuales = useCallback(() => {
    const { valorVivienda } = getViviendaEnganche({
      ingresos: inputNumber,
      tasa: tasaNumber * 0.01,
      RPI: DEFAULT_RPI,
    });

    const propietyValue = Number(valorVivienda.toFixed(2));
    const { enganche: _enganche, engancheNumber: _engancheNumber } = calcAmmountEnganche(
      propietyValue,
      includeDecimals,
      engancheMinimoPercentage,
    );
    const montoCredito = calculoMontoCredito(propietyValue, _engancheNumber);
    const pagoMensual = calculoMensualidad(montoCredito);
    const montoGastosEscritura = useDesembolsoMode
      ? calcAmmountGastosEscritura(propietyValue, gastosEscrituraPercentage)
      : 0;

    dispatch(setPerfiladorEnganche(_enganche));

    return {
      propietyValue: redondearNumero(propietyValue, DEFAULT_DECIMALES_REDONDEO),
      montoCredito: redondearNumero(montoCredito, DEFAULT_DECIMALES_REDONDEO),
      pagoMensual: redondearNumero(pagoMensual, DEFAULT_DECIMALES_REDONDEO),
      montoGastosEscritura: redondearNumero(
        montoGastosEscritura,
        DEFAULT_DECIMALES_REDONDEO,
      ),
    };
  }, [
    inputNumber,
    engancheMinimoPercentage,
    gastosEscrituraPercentage,
    calculoMontoCredito,
    calculoMensualidad,
    useDesembolsoMode,
  ]);

  const getAndSetIngresosCliente = useCallback(() => {
    const localIngresos = getPerfiladorClientIngresos();
    if (localIngresos) {
      const ingresosCurrency = convertirAMoneda(localIngresos);
      dispatch(setPerfiladorInputValue(ingresosCurrency));
    } else {
      dispatch(setPerfiladorInputValue(''));
    }
  }, [dispatch]);

  const getAndSetPropietyValue = useCallback(() => {
    const localClientPropNumber = getPerfiladorClientValuePropNumber();
    if (localClientPropNumber) {
      const value = convertirAMoneda(localClientPropNumber, includeDecimals);

      dispatch(
        setPerfiladorInputValueAndCalcEnganche({
          value,
          includeDecimals,
          engancheMinimoPercentage,
        }),
      );
    } else {
      dispatch(setPerfiladorInputValue(''));
    }
  }, [dispatch, includeDecimals, engancheMinimoPercentage]);

  const handleChangeValuePropiety = useCallback((value) => {
    dispatch(
      setPerfiladorInputValueAndCalcEnganche({
        value,
        includeDecimals,
        engancheMinimoPercentage,
      }),
    );
    storePerfiladorValuePropiety(value);
  }, [dispatch, engancheMinimoPercentage, includeDecimals]);

  const handleChangeIngresosValue = useCallback((value) => {
    dispatch(setPerfiladorInputValue(value));
    storeIngresosData(value);
  }, [dispatch]);

  const actionsByCalcMode = useMemo(() => {
    switch (calcMode) {
      case CALC_MODE_INGREOS_MENSUALES:
        return {
          onChange: handleChangeIngresosValue,
          getAndSet: getAndSetIngresosCliente,
          calculate: calcularIngresosMensuales,
        };
      case CALC_MODE_PRECIO_INMUEBLE:
      default:
        return {
          onChange: handleChangeValuePropiety,
          getAndSet: getAndSetPropietyValue,
          calculate: calcularPrecioInmueble,
        };
    }
  }, [
    calcMode,
    inputValue,
    engancheMinimoPercentage,
    gastosEscrituraPercentage,
    plazoNumber,
    engancheNumber,
    tasaNumber,
  ]);

  const onChange = useCallback(
    (event, value) => {
      actionsByCalcMode.onChange(value);
    },
    [dispatch, calcMode, autoCalcEnganche, engancheMinimoPercentage],
  );

  const onChangePlazo = useCallback((event, value) => {
    dispatch(setPerfiladorPlazo(value));
  }, [dispatch]);

  const onChangeEnganche = useCallback((event, value) => {
    dispatch(setPerfiladorEnganche(value));
  }, [dispatch]);

  useEffect(() => {
    actionsByCalcMode.getAndSet();
  }, [
    calcMode,
    engancheMinimoPercentage,
    gastosEscrituraPercentage,
    getAndSetIngresosCliente,
    getAndSetPropietyValue,
  ]);

  useEffect(() => {
    const resultadoCalculo = actionsByCalcMode.calculate();
    dispatch(setPerfiladorResultado(resultadoCalculo));
  }, [
    inputValue,
    plazoNumber,
    engancheNumber,
    calcularPrecioInmueble,
    calcularIngresosMensuales,
    dispatch,
  ]);

  useEffect(() => {
    if (Object.keys(initialData).length === 0) return;
    dispatch(setPerfiladorInitialDataValues(initialData));
    const { precio = {}, enganche: _enganche = {}, year = {} } = initialData;
    const precioV = precio.value || '';
    const engancheV = _enganche.value || '';
    const yearV = year.value || '';
    dispatch(setPerfiladorInitialValues({
      precio: precioV,
      enganche: engancheV,
      plazo: yearV,
    }));
  }, [initialData, dispatch]);

  useEffect(() => {
    if (!currentInmobiliariaId) return;
    dispatch(setConditionsByInmobiliariaId({ inmobiliariaId: currentInmobiliariaId }));
  }, [currentInmobiliariaId]);

  useEffect(() => {
    const newPercetage = calcAmmountPercentage(inputNumber, engancheNumber);
    dispatch(setPerfiladorPercentageEnganche(newPercetage));
  }, [inputNumber, engancheNumber]);

  return {
    inputValue,
    inputNumber,
    enganche,
    engancheNumber,
    plazo,
    plazoNumber,
    percentageEnganche,
    engancheMinimoPercentage,
    onChange,
    onChangePlazo,
    onChangeEnganche,
    ...resultado,
  };
};

export default usePerfilador;
