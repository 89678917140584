export const MINIMUM_CREDIT_VALUE = 1200000;
export const MINIMUM_ENGANCHE = 5;
export const DEFAULT_TASA = 0.10;
export const INPUT_SIMULATOR_MINIMUM_DESEMBOLSO_PERCENTAGE = 15;
export const INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE = 10;
export const INPUT_SIMULATOR_MINIMUN_ENGANCHE_DECIMAL = 0.10;
export const DEFAULT_TASAS = {
  5: 10.5,
  10: 10.5,
  15: 10.5,
  20: 10.5,
};
export const MONTHLY_OPTIONS = [
  {
    id: 5,
    name: '5 años',
    value: '5 años',
    disabled: false,
  },
  {
    id: 10,
    name: '10 años',
    value: '10 años',
    disabled: false,
  },
  {
    id: 15,
    name: '15 años',
    value: '15 años',
    disabled: false,
  },
  {
    id: 20,
    name: '20 años',
    value: '20 años',
    disabled: false,
  },
];

export const CREDITO_HIPOTECARIO_COTIZADOR_LINK_INITIAL_VALUES = {
  precio: {
    type: 'precio_styled',
    label: 'Precio de la propiedad',
    name: 'precio',
    required: true,
    isEmpty: false,
    value: 0,
    error: {
      isValid: false,
      errorMessage: '',
    },
  },
  enganche: {
    type: 'precio_styled',
    name: 'enganche',
    label: 'Enganche',
    required: true,
    value: 0,
    isEmpty: false,
    percentage: '10',
    disabled: false,
    focus: false,
    error: {
      isValid: false,
      errorMessage: '',
    },
  },
  year: {
    type: 'select',
    name: 'year',
    label: 'Plazo del crédito',
    required: true,
    value: '20 años',
    isEmpty: false,
    options: MONTHLY_OPTIONS,
  },
};

export const CREDITO_HIPOTECARIO_INT_VALUES = {
  precio: {
    label: 'Precio de la propiedad',
    type: 'precio',
    name: 'precio',
    required: true,
    isEmpty: true,
    value: 0,
    error: {
      isValid: false,
      errorMessage: '',
    },
  },
  enganche: {
    label: 'Enganche',
    type: 'precio',
    name: 'enganche',
    required: true,
    value: 0,
    isEmpty: true,
    percentage: '5',
    disabled: false,
    focus: false,
    error: {
      isValid: false,
      errorMessage: '',
    },
  },
  year: {
    label: 'Plazo',
    type: 'select_simple',
    name: 'year',
    required: true,
    value: '20 años',
    isEmpty: false,
    options: MONTHLY_OPTIONS,
  },
  prestamo: {
    label: 'Préstamo bancario',
    value: 0,
    format: 'MXN',
  },
  monthlyPaid: {
    label: 'Pago mensual',
    value: 0,
    format: 'MXN',
  },
};

export const CREDITO_HIPOTECARIO_DESEMBOLSO_INITIAL_VALUES = {
  precio: {
    label: 'Precio de la propiedad',
    type: 'precio_styled',
    name: 'precio',
    required: true,
    isEmpty: true,
    value: 0,
    error: {
      isValid: false,
      errorMessage: '',
    },
  },
  desembolso: {
    label: '¿Cuánto quieres desembolsar hoy?',
    type: 'precio_styled',
    name: 'desembolso',
    required: true,
    value: 0,
    isEmpty: true,
    percentage: '15',
    disabled: false,
    focus: false,
    error: {
      isValid: false,
      errorMessage: '',
    },
  },
  year: {
    label: '¿A Cuántos años?',
    type: 'select_simple',
    name: 'year',
    required: true,
    value: '20 años',
    isEmpty: false,
    options: MONTHLY_OPTIONS,
  },
  prestamo: {
    label: 'Préstamo bancario',
    value: 0,
    format: 'MXN',
  },
  monthlyPaid: {
    label: 'Pago mensual. Desde',
    value: 0,
    format: 'MXN',
  },
};

export const INFONAVIT_COTIZACION_BUTTON_TEXT = 'Ver cotización';
export const SIMULATOR_EDIT_BUTTON_TEXT = 'Editar';

export const INPUT_NAME_ENGANCHE = 'enganche';
export const INPUT_NAME_DESEMBOLSO = 'desembolso';

export const CALC_MODE_INGREOS_MENSUALES = 'CALC_MODE_INGRESOS_MENSUALES';
export const CALC_MODE_PRECIO_INMUEBLE = 'CALC_MODE_PRECIO_INMUEBLE';
export const CALC_MODE_CONDITIONS_EDITION = 'CALC_MODE_CONDITIONS_EDITION';

export const PERFILADOR_VARIANTE_LANDING = 'PERFILADOR_VARIANTE_LANDING';
export const PERFILADOR_VARIANTE_COTIZADOR_INMOBILIARIAS = 'PERFILADOR_VARIANTE_COTIZADOR_INMOBILIARIAS';
export const PERFILADOR_VARIANTE_COTIZATION = 'PERFILADOR_VARIANTE_COTIZATION';

export const DATA_TABS_BESTS_MATRIZ_OPTIONS = [
  {
    label: 'Menor desembolso inicial',
    value: 'bestInitialOutlay',
    disabled: false,
  },
  {
    label: 'Mejor opción financiera',
    value: 'bestFinantialOption',
    disabled: false,
  },
];
