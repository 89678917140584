/* eslint-disable max-len */
import React, {
  useEffect, useRef, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { FormLayout } from '../../layouts';
import { Button, GroupFields, Loader } from '../../../../components';

import useLoading from '../../../../hooks/useLoading';
import useError from '../../../../hooks/useError';
import { useProduct } from '../../../../hooks/useProduct';
import * as userAPI from '../../../../api/users';
import * as unprotectedAPI from '../../../../api/unprotected';
import * as companyConfigsAPI from '../../../../api/companyConfigs';

import useStyles from './useStyles';
import {
  AGENTE_CLIENTS_ROUTE,
  AGENTE_PERFILADOR_ROUTE,
  AGENT_CLIENT_COMPANY_TYPE,
  AGENT_CREATE_CLIENT_ACCOUNT,
  AGENTE_COTIZACION_LEAD_ROUTE,
  ENTITIES_REDICRECT_TEMPORARY,
  COMPANY, PROPIEDAD_VALOR_FIELD_NAME, REJECTED,
  CHIPLO_INMOBILIARIA_ID,
} from '../../../../constants';
import { getConsultPageConfigByFlow } from '../../../../utils/commons';
import { HeaderBar } from '../../components/HeaderBar';
import { getAuth, getUserId } from '../../../../utils';
import { createLeadAndNotify } from '../../../../utils/api/leads.utils';
import { SimpleModal } from '../../../../components/Modal';
import { generateSharedFieldValues } from '../../../../utils/clients';
import { saveSharedFieldValuesData } from '../../../../api/sharedFieldValues';
import { getPerfiladorPropietyValue, removeLocalAgentData } from '../../../../storage';
import { convertirAMoneda } from '../../../../utils/currency';

const PHONE = 'phone';
const OWNER_MODEL = 'Lead';

const CreateClient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef();
  // Fallback a la ruta por defecto
  const backRoute = location.state?.from || AGENTE_CLIENTS_ROUTE;
  const isFromPerfilador = backRoute === AGENTE_PERFILADOR_ROUTE;

  const [lead, setLead] = useState({});
  const [user, setUser] = useState({});
  const [showModalSimple, setShowModalSimple] = useState(false);
  const [showModalCustom, setShowModalCustom] = useState(false);
  const [sections, setSections] = useState([]);
  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    showError,
  } = useError();
  const { getDefaultProduct, getFlowType } = useProduct();
  const currentProduct = getDefaultProduct();

  const [pageConfig, setPageConfig] = useState({
    title: 'Crea tu cuenta para continuar con tu aplicación',
    agentSectionTitle: '',
  });

  const classes = useStyles();

  const handleChange = (event, value, position) => {
    const { name } = event.target;
    const newSections = sections.map((section, index) => {
      if (index !== position) return section;
      const newFields = section.fields.map((field) => (field.name === name ? { ...field, value, errorMessage: '' } : field));
      return { ...section, fields: newFields };
    });
    setSections(newSections);
  };
  // !Remove
  // eslint-disable-next-line no-unused-vars
  const isNotCompleted = () => sections.some((section) => {
    const { fields = [] } = section;
    return fields.some(({ config = {}, value = '', status = {} }) => {
      const { value: statusValue } = status;
      const { required = false, hide = false } = config;
      if (!hide && !required && statusValue === REJECTED) return true;
      if (hide || !required) return false;
      return value === '' || value === null || !value.length || statusValue === REJECTED;
    });
  });

  const getFieldsData = (fieldsIds) => fieldsIds.map((fieldId) => unprotectedAPI.getById(fieldId));
  const setDefaultValues = (fields) => fields.map((field) => {
    const { value = '', config = {} } = field;
    return !value && config?.defaultValue
      ? { ...field, value: config?.defaultValue }
      : { ...field, value };
  });

  const getFieldsStructure = async (payload) => {
    const fieldsData = await Promise.all(getFieldsData(payload.fields));
    const fieldsDataWithValues = setDefaultValues(fieldsData);
    return [{
      fields: fieldsDataWithValues,
      title: payload.title,
    },
    ];
  };

  const getCorrectPhone = (phoneText, addPrefix) => (addPrefix ? `+521${phoneText}` : phoneText);

  const transformDataToUpload = (filterFields, addPrefix = false) => {
    const newFields = sections.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).filter(({ name }) => !filterFields.includes(name)).map(({
      value, name: fieldName,
    }) => ({
      fieldName,
      value: fieldName === PHONE ? getCorrectPhone(value, addPrefix) : value,
    })).reduce((acc, { fieldName, value }) => ({
      ...acc,
      [fieldName]: value,
    }), {});
    const extraData = { propiedadValor: newFields.propiedadvalor, agentId: getUserId() };
    const firstName = `${newFields.firstName} ${newFields.secondName}`;
    const clientObject = {
      ...newFields, firstName, source: 'Agente Inmobiliario', extraData, company: COMPANY,
    };
    return clientObject;
  };

  const getClientsFields = async () => {
    const companyConfigs = await companyConfigsAPI.getAllCompanyConfigs(COMPANY);
    const companyConfig = companyConfigs.find(({ type: { name = '' } = {} }) => name === AGENT_CLIENT_COMPANY_TYPE);
    const flowType = getFlowType(currentProduct.name);
    if (companyConfig && companyConfig.payload) {
      return companyConfig.payload.flows.find((_flow) => _flow.flow === flowType);
    }
    return {};
  };

  const changeRequiered = (fields) => fields.map((field) => (
    {
      ...field,
      config: {
        ...field.config,
        required: Object.keys(field.config).includes('agentRequired') ? field.config?.agentRequired : field.config.required,
      },
    }));

  const fillPropietyValueIfExist = (fields) => {
    const localPropietyValue = getPerfiladorPropietyValue();
    if (!isFromPerfilador || !localPropietyValue) return fields;
    return fields.map((field) => {
      if (field?.name === PROPIEDAD_VALOR_FIELD_NAME) {
        return {
          ...field,
          value: convertirAMoneda(localPropietyValue),
        };
      }
      return field;
    });
  };

  const fetchData = async () => {
    try {
      startLoading();
      const userData = getAuth();
      const payload = await getClientsFields();
      const fieldsData = await getFieldsStructure(payload);
      const fieldsNewRequired = changeRequiered(fieldsData[0].fields);
      const fieldsWithPropietyValue = fillPropietyValueIfExist(fieldsNewRequired);
      const userComplete = await userAPI.getUserById(userData._id);
      console.log('userComplete', userComplete);
      const _pageConfig = await getConsultPageConfigByFlow(
        currentProduct.flowName,
        AGENT_CREATE_CLIENT_ACCOUNT,
        AGENT_CREATE_CLIENT_ACCOUNT,
      );
      setUser(userComplete);
      setPageConfig(_pageConfig);
      setSections([{ ...fieldsData[0], fields: fieldsWithPropietyValue }]);
      endLoading();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to fetch  Data');
      endLoading();
    }
  };

  const createClient = async (dataToUpload) => {
    const leadResponse = await createLeadAndNotify(dataToUpload, false);
    const { _id } = leadResponse;
    setLead(leadResponse);
    return _id;
  };

  const saveSharedFieldValues = async (fields, clientId, ownerModel) => {
    const dataSharedFieldValues = generateSharedFieldValues(fields, clientId, ownerModel);
    await Promise.all(
      dataSharedFieldValues.map(
        async (itemSharedFieldValue) => saveSharedFieldValuesData(itemSharedFieldValue),
      ),
    );
  };

  const transformDataShared = (filterFields) => {
    const newFields = sections.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).filter(({ name }) => !filterFields.includes(name)).map(({
      _id, value,
    }) => ({
      _id,
      value,
    }));
    return newFields;
  };

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      startLoading();
      const { entity = {} } = user;
      const newClientData = transformDataToUpload([], true);
      const isValidForm = formRef.current.checkValidity();
      if (!isValidForm) {
        formRef.current.reportValidity();
        endLoading();
        return;
      }
      const temporalClientId = await createClient(newClientData);
      const sharedValues = transformDataShared([]);
      await saveSharedFieldValues(sharedValues, temporalClientId, OWNER_MODEL);
      removeLocalAgentData();
      if (entity?.showRedirectCalificador || ENTITIES_REDICRECT_TEMPORARY.includes(entity._id.toString())) setShowModalCustom(true);
      else setShowModalSimple(true);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
    } finally {
      endLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const back = () => {
    navigate(backRoute);
  };

  const goHome = () => {
    navigate(AGENTE_CLIENTS_ROUTE);
  };

  const goCotization = () => {
    const routeCotization = AGENTE_COTIZACION_LEAD_ROUTE(user?.entity?._id || CHIPLO_INMOBILIARIA_ID, lead?._id, user?._id);
    navigate(routeCotization);
  };

  return (
    <div className={classes.mainContainer}>
      <Loader
        open={isLoading}
      />
      <SimpleModal
        showModal={showModalSimple}
        onCancel={goHome}
        title="Cliente creado correctamente, Chiplo lo contactará pronto."
      >
        <Box className={classes.buttonContainer}>
          <Button onClick={goHome} fullWidth>Aceptar</Button>
        </Box>
      </SimpleModal>
      <SimpleModal
        showModal={showModalCustom}
        onCancel={goHome}
        title="Cliente creado correctamente, ¿Deseas llenar los datos de cotización?"
      >
        <Box className={classes.buttonContainerCustom}>
          <Button onClick={goHome} fullWidth>No</Button>
          <Button onClick={goCotization} fullWidth>Continuar a Cotización</Button>
        </Box>
      </SimpleModal>
      <div className={classes.background}>
        <HeaderBar
          title={pageConfig.navbarTitle}
          back={back}
        />
      </div>
      <FormLayout
        submit={onSubmit}
        titleContainerClass={classes.title}
        buttonTitle={pageConfig.buttonTitle}
        formRef={formRef}
      >
        <div
          className={classes.innerContainer}
        >
          <Typography variant="h2" className={classes.formTitle}>
            Completa los siguientes
          </Typography>
          <Typography variant="h3" className={classes.subTitle}>
            Datos
          </Typography>
          {
            sections.map(({ fields, title }, index) => (
              <GroupFields
                fields={fields}
                title={title}
                position={index}
                onChange={handleChange}
                makeFieldsRequired
              />
            ))
          }
        </div>
      </FormLayout>
    </div>
  );
};

export default CreateClient;
