// !Remove
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, {
  useRef, memo, useCallback, useEffect,
  useState,
} from 'react';
import {
  InputAdornment, TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import isValidDigit from './validator';
import { currencyFormatter } from './formatter';
import useStyles from './useStyles';
import useEnabler from '../../../hooks/useEnabler';
import {
  contieneNumeros,
  convertirANumero,
  formatMoney,
  formatToNumber,
} from '../../../utils/currency';
import { isInputEmpty, isNumber } from '../../../utils';

const ERROR_MESSAGE_EMPTY = 'Rellena este campo';

const InputPriceStyled = ({
  isFloat,
  id,
  name,
  label,
  value,
  type,
  disabled,
  extraInputClassName,
  extraInputLabelClassName,
  className,
  fullWidth,
  multiline,
  startAdornment,
  endAdornment,
  onChange,
  onKeyDown,
  helperText,
  hide,
  required,
  validations,
  error,
}) => {
  const classes = useStyles();
  const [mValue, setValue] = useState(value);

  const {
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const inputRef = useRef(null);
  // false: validado
  // true: no validado
  const execValidations = (inputValue) => {
    const validationsArray = validations.map((validationFunc) => validationFunc(inputValue));
    const isValidation = validationsArray.filter(({ isValid }) => !isValid);
    const todo = { isValid: !isValidation.length, errorMessage: isValidation[0]?.errorMessage || ERROR_MESSAGE_EMPTY };
    return todo;
  };

  const isValueValid = (data) => {
    if (isInputEmpty(data) && !required) return true;
    const regex = /^\d+\.?(\d{1,2})?$/;
    return isInputEmpty(data) || (isNumber(data) && regex.test(data));
  };

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value: _value } = target;
    // eslint-disable-next-line no-param-reassign
    event.target.value = _value;
    const { isValid, errorMessage } = execValidations(formatToNumber(_value));
    if (_value && isValid) {
      target.setCustomValidity('');
      disableError();
    } else {
      target.setCustomValidity(errorMessage);
      enableError();
    }
    const newValue = _value || '$0';
    onChange(event, formatMoney(newValue));
  }, [onChange, isFloat]);

  const handleOnKeyDown = useCallback((event) => {
    const charCode = event.which ? event.which : event.keyCode;
    const ctrlCode = event.ctrlKey ? event.ctrlKey : event.metaKey;
    const { value: _value } = event.target;

    if (!isValidDigit(charCode, ctrlCode, _value)) event.preventDefault();
    onKeyDown(event);
  }, []);

  const onClear = () => {
    setValue('');
    onChange('');
  };

  useEffect(() => {
    if (isInputEmpty(value)) {
      enableError();
      inputRef.current.setCustomValidity(ERROR_MESSAGE_EMPTY);
    } else if (!error.isValid) {
      inputRef.current.setCustomValidity(error.errorMessage);
    } else {
      inputRef.current.setCustomValidity('');
    }
  }, [enableError, value, error]);

  useEffect(() => {
    if (!value) {
      setValue('');
      return;
    }
    const formattedNumber = formatToNumber(value);
    const isValid = isValueValid(formattedNumber);

    if (isValid) {
      setValue(formatMoney(formattedNumber));
    }
  }, [value]);

  return (
    <TextField
      hiddenLabel={hide}
      id={id}
      name={name}
      placeholder="0"
      hidden
      label={label}
      value={mValue}
      type={type}
      required={required}
      inputRef={inputRef}
      // required={required}
      disabled={disabled}
      className={clsx(classes.textField, className)}
      fullWidth={fullWidth}
      variant="filled"
      multiline={multiline}
      InputLabelProps={{
        classes: {
          root: clsx(classes.InputLabelPropsRoot, {
            [classes.delete]: hide,
          }),
          focused: classes.InputLabelPropsFocused,
        },
      }}
      InputProps={{
        classes: {
          root: clsx(classes.InputPropsRoot, extraInputClassName, {
            [classes.hide]: hide,
          }),
          focused: classes.InputPropsFocused,
          input: clsx(
            classes.InputPropsInput,
            { [classes.InputPropsInputTextArea]: multiline },
          ),
          disabled: classes.InputPropsDisabled,
          error: classes.InputPropsError,
        },
        disableUnderline: true,
        startAdornment: (
          startAdornment
            ? (
              <InputAdornment position="start">
                {startAdornment}
              </InputAdornment>
            )
            : ''
        ),
        endAdornment: (
          endAdornment
            ? (
              <InputAdornment position="end">
                {endAdornment}
              </InputAdornment>
            )
            : ''
        ),
      }}
      helperText={!hide && helperText}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
    />
  );
};

InputPriceStyled.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  isFloat: PropTypes.bool,
  type: PropTypes.string,
  extraInputClassName: PropTypes.string,
  extraInputLabelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  validations: PropTypes.arrayOf(PropTypes.func),
  startAdornment: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  endAdornment: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  helperText: PropTypes.string,
  hide: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.object,
};

InputPriceStyled.defaultProps = {
  id: undefined,
  isFloat: false,
  name: undefined,
  label: '',
  value: '',
  type: 'text',
  disabled: false,
  extraInputClassName: '',
  extraInputLabelClassName: '',
  className: '',
  fullWidth: true,
  multiline: false,
  startAdornment: '',
  endAdornment: '',
  onChange: () => { },
  onKeyDown: () => { },
  helperText: '',
  hide: false,
  required: false,
  validations: [],
  error: {
    isValid: false,
    errorMessage: '',
  },
};

export default memo(React.forwardRef(InputPriceStyled));
